import './App.css';
import cover from './assets/cover.png';
import ci from './assets/ci.png';

function App() {
  return (
    <div className="container">
      <img src={cover} alt="cover" className="cover" />
      <div className="center-content">
        <img src={ci} alt="ci" className="ci" />
        <p className="coming-soon">Coming Soon!</p>
      </div>
    </div>
  );
}

export default App;
